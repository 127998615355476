import React, {ReactNode, useEffect, useRef, useState} from 'react';
import classNames from 'classnames/bind';
import styles from './edit-info.module.scss';
import {Field, Form, Formik} from 'formik';
import {ClosableProps} from '../../components/dialog';
import {asyncCall} from '../../util/util';
import {useMountedState} from 'react-use';
import {useInjection} from 'inversify-react';
import {toast} from 'react-toastify';
import {CustomerApi} from '../../model/customer/customer-api';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {strSaved} from '../../data/strings';
import {InputBox, NumberInputBox, SelectInputBox} from './edit-common';
import {DialogManager} from '../../components/dialog-manager';
import BusinessTypePop from '../../components/business-type-pop';
import CertificateStatusPop from '../../components/certificate-status-pop';
import {certStatusList, certToStr} from '../report/report-util';
import {numberOnly} from '../../util/str-util';
import FormikErrorMsg from '../../components/formik-error-msg';
import TooltipPop from '../../components/tooltip-pop';

const cx = classNames.bind(styles);

interface StringInputBoxProps {
    title: string;
    name: string;
    value: string;
    placeholder?: string;
    readonly?: boolean;
    cn?: string;
    openPop?: () => void;
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void;
}

const StringInputBox = ({
                            title,
                            name,
                            value,
                            placeholder,
                            readonly = false,
                            cn,
                            openPop,
                            setFieldValue
                        }: StringInputBoxProps) => {

    return <div className={cx('input-box', cn)}>
        <label>{title}</label>
        <Field name={name} type='text' maxLength={20}
               className={cx(openPop && 'select-pop')}
               placeholder={placeholder ?? '입력'}
               value={value == null ? '' : value}
               onClick={openPop}
               readOnly={readonly}
               onChange={(e: any) => setFieldValue(name, e.target.value)}/>
        {cn === 'error' && <div className='error-message'><i></i>필수값을 입력해 주세요.</div>}
    </div>
}

const CustomInput = ({field, form, ...props}: { field: any, form: any }) => {
    if (field.value == null) field.value = ''; //value가 null이면 배열에 insert할 때 업데이트가 제대로 안 됨
    return <input {...field} {...props} />;
}

interface EstbDtInputBoxProps {
    name?: string;
    title?: ReactNode;
    unit?: string;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    readOnly?: boolean;
    inputboxCn?: string;
    inputWidth?: string;
    minLength?: number;
    maxLength?: number;
    type?: string;
    children?: JSX.Element;
    requiredField?: boolean;
    tooltip?: string | { title: string, desc: string }[];
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EstbDtInputBox = ({
                            name,
                            title,
                            unit,
                            value,
                            placeholder = "입력",
                            disabled,
                            readOnly,
                            inputboxCn,
                            inputWidth,
                            minLength,
                            maxLength,
                            type,
                            children,
                            requiredField,
                            tooltip,
                            onClick = () => null,
                        }: //  onChange = () => null,
//  onChange
                            EstbDtInputBoxProps) => {
    return (
        <div className={`input-box ${inputboxCn}`}>
            {title && (
                <label>
                    {title}
                    {requiredField && <i className="required-field"> *</i>}
                    {tooltip && <TooltipPop contents={tooltip} eventStop={true} />}
                </label>
            )}
            <div
                className={`for-unit ${unit ? "hasUnit" : ""}`}
                style={{width: inputWidth ? inputWidth : "13.3rem"}}
            >
                {children ? (
                    children
                ) : name ? (
                    <Field
                        className="input-txt"
                        name={name}
                        component={CustomInput}
                        placeholder={placeholder}
                        type={type}
                        disabled={disabled}
                        readOnly={readOnly}
                        maxLength={maxLength}
                        min={minLength}
                        onClick={onClick}
                    />
                ) : (
                    <input
                        className="input-txt"
                        type="text"
                        value={value ?? ""}
                        readOnly
                    />
                )}

                {unit != null && <span className="unit">{unit}</span>}
                {name && <FormikErrorMsg name={name}/>}
            </div>
        </div>
    );
};

interface EditCompanyInfoProps extends ClosableProps {
    data: CustomerDetail;
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>
}

const EditCompanyInfo = ({data, setData, onClose}: EditCompanyInfoProps) => {
    const isMounted = useMountedState();
    const customerApi = useInjection(CustomerApi);
    const dialogManager = useInjection(DialogManager);
    // const [requiredField, setRequiredField] = useState({bzcCdNm: false, employee: false});
    const [bzcCdNmChk, setBzcCdNmChk] = useState(false);
    const [employeeChk, setEmployeeChk] = useState(false);
    const [estbDtChk, setEstbDtChk] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!data.customer?.companyInfo.bzcCdNm) {
            setBzcCdNmChk(true);
        }

        if (!data.summary?.KED5002['LABORER_SUM']) {
            setEmployeeChk(true);
        }

        if (!data.summary?.KED5002['ESTB_DT']) {
            setEstbDtChk(true);
        }

    }, [setBzcCdNmChk, setEmployeeChk, setEstbDtChk]);

    const customer = data.customer;
    if (!customer) return null;

    const editStock = !data.corpReg?.stock.cntIssuedStock;

    let femaleCompany = data.customer?.companyInfo.femaleCompany;

    if (data.customer?.rep !== null && data.customer?.rep) {
        if (data.customer?.rep[0].female !== null && typeof data.customer?.rep[0].female !== "undefined" && femaleCompany !== data.customer?.rep[0].female) {
            femaleCompany = data.customer?.rep[0].female;
        }
    }

    let femaleCompanyCertInfo = "선택";

    if (femaleCompany) {
        if (data.customer?.companyInfo.femaleCompanyCertYn) {
            femaleCompanyCertInfo = "여성기업(인증)";
        } else {
            femaleCompanyCertInfo = "여성기업(미인증)";
        }
    } else {
        if (data.customer?.companyInfo.femaleCompanyCertYn) {
            femaleCompanyCertInfo = "여성기업(인증)";
        }
    }

    const sincereReport = data.customer?.companyInfo.sincereReportYn === "Y" ? "해당" : "해당없음";

    const initialValues: any = {
        ...data.customer?.companyInfo,
        'enpNm': data.customer?.enpNm,
        'femaleCom': femaleCompanyCertInfo,
        editStock: editStock,
        'sincereReport': sincereReport,
        'enpSize': data.customer?.companyInfo.enpSize ?? (data.summary?.KED5002["ENP_SZE"] ?? ""),
        'estbFcd': data.customer?.companyInfo.estbFcd ?? (data.summary?.KED5002["ESTB_FCD"] ?? ""),
    };

    const getCertStat = (values: any) => {
        const certStat: any = {};
        for (const o of certStatusList) {
            certStat[o.id] = values[o.id] === 'Y';
        }
        return certStat;
    }

    const setCertStat = (cert: {
        [k: string]: boolean
    }, setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void) => {
        for (const k in cert) {
            setFieldValue(k, cert[k] ? 'Y' : 'N');
        }
    }

    return (
        <div className={cx('edit-info')}>
            <Formik
                initialValues={initialValues}
                onSubmit={(value) => {
                    if (value.bzcCdNm === null || value.bzcCdNm.length === 0) {
                        setBzcCdNmChk(true);
                        return;
                    }

                    if (data.summary?.KED5002['LABORER_SUM'] === null && value.employee === null) {
                        setEmployeeChk(true);
                        return;
                    }

                    value.femaleCompany = femaleCompany;

                    if (data.summary?.KED5002['ESTB_DT'] === null && value.estbDt === null) {
                        setEstbDtChk(true);
                        return;
                    } else {
                        if (value.estbDt.length > 8 || value.estbDt.length < 8) {
                            dialogManager.alert("설립일자 날짜가 유효하지 않습니다.");
                            return;
                        } else {
                            const dateCheck = new Date(value.estbDt.substring(0, 4) + "-" + value.estbDt.substring(4, 6) + "-" + value.estbDt.substring(6, 8));

                            if (dateCheck.toString() === "Invalid Date") {
                                dialogManager.alert("설립일자 날짜가 유효하지 않습니다.");
                                return;
                            }
                        }
                    }

                    if(isNaN(value.bno) || value.bno.length < 10) {
                        dialogManager.alert("사업자등록번호가 유효하지 않습니다.");
                        return;
                    }

                    value.femaleCompany = femaleCompany;

                    if (value.femaleCom.includes('여성기업')) {
                        value.femaleCompanyCertYn = value.femaleCom === '여성기업(인증)';

                    } else {
                        // value.femaleCompany = false;
                        value.femaleCompanyCertYn = false;
                    }

                    if (value.sincereReport === "해당") {
                        value.sincereReportYn = "Y";
                    } else {
                        value.sincereReportYn = "N";
                    }

                    asyncCall(customerApi.saveCompanyInfo, [customer.id, value], result => {
                        setData(data => {
                            if (data.customer && data.summary) return {
                                ...data
                                , customer: {...data.customer, ...result.customer}
                                , summary: {...data.summary, ...result.summary}
                            }
                            else return data;
                        });
                        onClose();
                        toast.success(strSaved);
                    }, isMounted);
                }}>
                {({values, setFieldValue}) =>
                    <Form>
                        <div className={cx('button-area')}>
                            <button className={cx('back')} type='button' onClick={onClose}><i></i>돌아가기</button>
                            <div>
                                <button className={cx('save')} type='submit'><i></i>저장하기</button>
                            </div>

                        </div>
                        <div className={cx('input-area')} ref={ref}>
                            <div className={cx('scroll')}>
                                <p className={cx('title')}>기업개요</p>
                                <div className={cx('form-box')}>
                                    <InputBox title='기업명' name='enpNm' maxLength={20} placeholder='기업명을 입력해 주세요.'
                                              readOnly={!!data.summary?.KED5002['ENP_NM']} inputWidth='52rem'
                                              inputboxCn={cx('input-box')}/>
                                    <div className='error-box'>
                                        <EstbDtInputBox
                                            title='설립일자'
                                            name='estbDt'
                                            placeholder='설립일 8자리 입력 (YYYYMMDD)'
                                            readOnly={!!data.summary?.KED5002['ESTB_DT'] && data.customer?.enpTyp === "1"}
                                            inputWidth='52rem'
                                            inputboxCn={cx('input-box', estbDtChk && 'error')}
                                            onChange={(e) => {
                                                setFieldValue('estbDt', numberOnly(e.target.value));
                                                e.target.value.length > 0 && setEstbDtChk(false);
                                            }}
                                        />
                                        {estbDtChk &&
                                            <div className='error-message' style={{left: 110}}><i></i>필수값을 입력해 주세요.
                                            </div>}
                                    </div>
                                    <InputBox title='사업자등록번호' name='bno' maxLength={10} placeholder='사업자등록번호를 입력해 주세요.' readOnly={!!data.customer?.companyInfo.bno} inputWidth='52rem' inputboxCn={cx('input-box')}/>

                                    <SelectInputBox
                                        title='기업규모'
                                        name='enpSize'
                                        value={values.enpSize ?? ""}
                                        selectList={['소상공인', '소기업', '중기업', '중소기업', '중견기업', '대기업']}
                                        inputboxCn={cx('select-input-box')}
                                        inputWidth='52rem'
                                        openAbove={true}/>
                                    <div className='error-box'>
                                        <StringInputBox
                                            title='업종'
                                            name='bzcCdNm'
                                            value={values.bzcCdNm}
                                            cn={cx(bzcCdNmChk && 'error')}
                                            readonly={true}
                                            openPop={() => {
                                                dialogManager.open(BusinessTypePop, {
                                                    code: values.bzcCd,
                                                    setBusinessField: (code: string, name: string) => {
                                                        setFieldValue('bzcCd', code);
                                                        setFieldValue('bzcCdNm', name);
                                                    }
                                                });
                                                setBzcCdNmChk(false);
                                            }}
                                            setFieldValue={setFieldValue}/>
                                        {bzcCdNmChk &&
                                            <div className='error-message' style={{left: 110}}><i></i>필수값을 입력해 주세요.
                                            </div>}
                                    </div>
                                    <SelectInputBox
                                        title='설립형태'
                                        name='estbFcd'
                                        value={values.estbFcd ?? ""}
                                        selectList={['신규설립(개업)', '법인전환', '현물출자 법인전환', '세감면 사업양수도', '조직변경', '신설합병', '분할신설', '사업승계(상속)']}
                                        inputboxCn={cx('select-input-box')}
                                        inputWidth='52rem'
                                        openAbove={true}/>
                                    <InputBox title='주요 제품' name='mainProduct'
                                              placeholder={data.summary?.KED5002['PD_NM'] && data.summary?.KED5002['PD_NM'].length > 0 ? data.summary?.KED5002['PD_NM'] : '입력'}
                                              inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <div className='error-box'>
                                        <NumberInputBox title='상시근로자수' name='employee' value={values.employee} unit='명'
                                                        inputWidth='52rem'
                                                        inputboxCn={cx('input-box', employeeChk && 'error')}
                                                        placeholder={data.summary?.KED5002['LABORER_SUM'] ?? '입력'}
                                                        onChange={(e) => {
                                                            setFieldValue('employee', numberOnly(e.target.value));
                                                            e.target.value.length > 0 && setEmployeeChk(false);
                                                        }}
                                        />
                                        {employeeChk &&
                                            <div className='error-message' style={{left: 110}}><i></i>필수값을 입력해 주세요.
                                            </div>}
                                    </div>
                                    <InputBox title='본점 주소' name='addr' inputWidth='52rem'
                                              inputboxCn={cx('input-box')}/>
                                    <InputBox title='홈페이지 주소' name='homepage'
                                              placeholder={data.summary?.KED5002['HPAGE_URL'] && data.summary?.KED5002['HPAGE_URL'].length > 0 ? data.summary?.KED5002['HPAGE_URL'] : '입력'}
                                              inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <SelectInputBox
                                        title='성실신고대상'
                                        name='sincereReport'
                                        value={values.femaleComany ?? false}
                                        selectList={['해당', '해당없음']}
                                        inputboxCn={cx('select-input-box')}
                                        inputWidth='52rem'
                                        openAbove={true}/>
                                    {
                                        data.customer?.enpTyp !== "2" ?
                                            <>
                                                <NumberInputBox title='자본금' name='paymentFund'
                                                                value={values.paymentFund} readOnly={!editStock}
                                                                unit='원' inputWidth='52rem'
                                                                inputboxCn={cx('input-box')}/>
                                                <NumberInputBox title='발행주식합계' name='issStkCn' value={values.issStkCn}
                                                                readOnly={!editStock} unit='주' inputWidth='52rem'
                                                                inputboxCn={cx('input-box')}/>
                                                <NumberInputBox title='1주의 금액(액면가)' name='stkFvalAm'
                                                                value={values.stkFvalAm} readOnly={!editStock} unit='원'
                                                                inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                            </>
                                            : null
                                    }
                                    <p className={cx('sub-title')}>해당사항이 있는 경우 추가로 입력해 주세요.</p>
                                    <SelectInputBox
                                        title='여성기업여부'
                                        name='femaleCom'
                                        value={values.femaleComany ?? false}
                                        selectList={['선택', '여성기업(인증)', '여성기업(미인증)']}
                                        inputboxCn={cx('select-input-box')}
                                        inputWidth='52rem'
                                        openAbove={true}/>
                                    <StringInputBox
                                        title='기업인증현황'
                                        name='certificateStatus'
                                        placeholder='선택'
                                        value={certToStr(values)}
                                        readonly={true}
                                        openPop={() => dialogManager.open(CertificateStatusPop, {
                                            list: certStatusList,
                                            certStat: getCertStat(values),
                                            setCertStat: (cert: { [k: string]: boolean }) => {
                                                setCertStat(cert, setFieldValue)
                                            }
                                        })}
                                        setFieldValue={setFieldValue}/>
                                    <InputBox title='산업재산권현황' name='indPropRght' value={values.indPropRght}
                                              placeholder='입력 (입력 예시: 특허권 2건, 상표권 1건)' inputWidth='52rem'
                                              inputboxCn={cx('input-box')}/>
                                    <SelectInputBox
                                        title='투자단계'
                                        name='investStage'
                                        value={values.investStage || ''}
                                        selectList={['시드투자', 'PreA', '시리즈A', '시리즈B', '시리즈C']}
                                        inputboxCn={cx('select-input-box')}
                                        inputWidth='52rem'
                                        openAbove={true}/>
                                    <NumberInputBox title='누적투자유치액' name='investAmount' value={values.investAmount}
                                                    unit='원' inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    );
};

export default EditCompanyInfo;