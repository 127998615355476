import classnames from "classnames/bind";
import styles from "./business-field-pop.module.scss";
import {Field, Form, Formik, FormikErrors, FormikValues} from "formik";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { CommonCode } from "../../../model/common/dto/common-code";
import { CommonCodeApi } from "../../../model/common/common-code-api";
import { CompanyPfKw } from "../../../model/customer/dto/customer-mna-brokerage-dto";
import React from "react";

const cx = classnames.bind(styles);

interface BusinessFieldPopProps {
    info: CompanyPfKw[]
    setFieldValue:(field: string, value: any, shouldValidate?: (boolean | undefined)) => Promise<void | FormikErrors<FormikValues>>
    onClose: VoidFunction
}

type ChildCodeType = {[key: string]: CommonCode[]};
type PfKwFormType = {[key: string]: string[]};

const BusinessFieldPop = ({info, setFieldValue, onClose}:BusinessFieldPopProps) => {
    const commonCodeApi = useInjection(CommonCodeApi);
    const [codes, setCodes] = useState<CommonCode[]>([]);
    const [childCodes, setChildCodes] = useState<ChildCodeType>({});
    const [none, setNone] = useState(info && info.length === 1 && info[0].parentCode === "해당영역 없음" ? true : false);

    useEffect(() => {
        getCodes();
    }, []);

    const makeInitialValues = () => {
        let form: PfKwFormType = {};

        console.log(info);

        if (info) {
            info.forEach((v) => {
                form = {
                    ...form,
                    [v.parentCode]: (v.subCodes ?? []),
                }
            });
        }

        return form;
    }

    const getCodes = async () => {
        const result = await commonCodeApi.getCodes("POLICY_FUND_KW");
        
        if(result) setCodes(result);

        let tempChildeCodes = {};

        info?.map(async (code) => {
            const childResult = await commonCodeApi.getChildCodes("POLICY_FUND_KW", code.parentCode);

            if (childResult) {
                tempChildeCodes = {...tempChildeCodes, [code.parentCode]: childResult};
            }

            setChildCodes(tempChildeCodes);
        })
    }

    const checkParentCode = async (parentCode: string) => {
        setNone(false);

        if (!childCodes[parentCode]) {
            const result = await commonCodeApi.getChildCodes("POLICY_FUND_KW", parentCode);
            setChildCodes({...childCodes, [parentCode]: result});
        } else {
            delete childCodes[parentCode]
            setChildCodes({...childCodes})
        }
    }

    const uncheckAllChildCode = (values: PfKwFormType, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<PfKwFormType>>) => {
        // for (let key in values) {
        //     if(values[key] && values[key].length > 0) {
        //         childCodes[key]?.some((child) => {
        //             const childInput = document.getElementById(key + child.no) as HTMLInputElement;
        //             console.log(childInput.checked);

        //             if (childInput && childInput !== null && childInput.checked) {
        //                 console.log(childInput);
        //                 childInput.click();
        //             }
        //         })
        //     }
        // }

        // codes.map((code) => {
        //     const parentInput = document.getElementById(code.code) as HTMLInputElement;

        //     if(parentInput && parentInput !== null && parentInput.checked) {
        //         parentInput.click();
        //     }
        // })

        for (let key in values) {
            setFieldValue(key, []);
            delete values[key];
        }

        setChildCodes({});
        setNone(true);
    }

    const parseCodeComponent = (parent: CommonCode, values: PfKwFormType) => {
        return (
            <React.Fragment key={parent!.code}>
                <div className={cx('type-box', 'ver2', (values[parent.code] && values[parent.code].length > 0) ? 'on' : '')}>
                    {/* <input id={parent!.code} name={parent!.code} type='checkbox' checked={ (values[parent.code] && values[parent.code].length > 0) === true} onChange={(e) => {
                        checkParentCode(parent.code, e.target.checked);
                    }} /><span>{parent!.codeVal}</span> */}
                    <button className={cx('radio-box')} type="button" onClick={() => { checkParentCode(parent.code); }}><i></i><span>{parent!.codeVal}</span></button>
                    {
                        (childCodes[parent.code] && parent.code !== "해당영역 없음") && <div className={cx('keyword-box')}>
                            {
                                childCodes[parent.code].map((child) => {
                                    return (
                                        <p key={child.code} className={cx('keyword')}>
                                            <Field id={`${parent.code}${child.no}`} name={`${parent.code}`} value={`${child.code}`} type='checkbox' />
                                            <label className={cx('label-box')} htmlFor={`${parent.code}${child.no}`}>{child.codeVal}</label>
                                        </p>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

    return(
        <div className={`full-background`}>
            <div className={`pop-inner ${cx('business-field-pop')}`}>
                <div className={cx('title')}>
                    사업영역을 선택해 주세요.
                </div>
                {codes && codes.length > 0 && 
                <Formik
                    enableReinitialize={true}
                    initialValues={makeInitialValues()}
                    onSubmit={(values: PfKwFormType) => {
                        console.log(values);
                        console.log(none);

                        const newList: CompanyPfKw[] = [];
                        let businessName: string[] = [];

                        if(!none) {
                            for (let key in values) {
                                if(values[key] && values[key].length > 0) {
                                    businessName = [...businessName, ...values[key]];
    
                                    newList.push({
                                        parentCode: key,
                                        subCodes: values[key]
                                    });
                                }
                            }
                        } else {
                            businessName.push("해당영역 없음");
                            newList.push({
                                parentCode: "해당영역 없음",
                                subCodes: []
                            })
                        }

                        setFieldValue('bizAreaInfo', newList);
                        setFieldValue('businessName', businessName);
                        onClose();
                    }}>
                    {({values, setFieldValue, setValues}) =>
                        <Form>
                            <div className={cx('type-list')}>
                                {
                                    codes.map((code) => parseCodeComponent(code, values))
                                }
                                <div className={cx('type-box')}>
                                    <label className={cx('radio-box2')}>
                                        <input type='checkbox' value={`해당영역 없음`} checked={none} onChange={(e) => {
                                            setValues({});
                                            setChildCodes({});
                                            setNone(true);
                                        }}/><span>해당영역 없음</span>
                                    </label>
                                </div>
                            </div>
                            <div className={cx('btn-area')}>
                                <button type='button' className={`close-btn`} onClick={onClose}>닫기</button>
                                <button type='submit' disabled={false} className={cx('save-btn')}><i></i>선택</button>
                            </div>
                        </Form>
                    }
                </Formik>}
            </div>
        </div>
    );
}

export default BusinessFieldPop;