import styles from './mna-info.module.scss';
import classnames from 'classnames/bind';
import React from "react";
import {Field, Form, Formik} from "formik";
import TooltipPop from "../../components/tooltip-pop";
import CompanyTypeForm from "./component/company-type-form";
import {CustomerDetail} from "../../model/customer/dto/customer-detail";
import ConnectChannelTalk from "./component/connect-channel-talk";
import { useInjection } from 'inversify-react';
import { CustomerInputApi } from '../../model/customer/customer-input-api';
import { CustomerMnaBrokerageDto, EarningsEstimate } from '../../model/customer/dto/customer-mna-brokerage-dto';
import { CustomerMnaBrokerageForm } from '../../model/customer/form/customer-mna-brokerage-form';
import { DialogManager } from '../../components/dialog-manager';
import MnaIntroductionFeePolicyPop from "./component/mna-introduction-fee-policy-pop";
import { CustomerApi } from '../../model/customer/customer-api';

const cx = classnames.bind(styles);

const perform: EarningsEstimate[] = [
    {
        year: new Date().getFullYear(),
        sales: 0,
        salesGrowthRate: 0,
        profitRate: 0
    },
    {
        year: new Date().getFullYear() + 1,
        sales: 0,
        salesGrowthRate: 0,
        profitRate: 0
    },
    {
        year: new Date().getFullYear() + 2,
        sales: 0,
        salesGrowthRate: 0,
        profitRate: 0
    },
    {
        year: new Date().getFullYear() + 3,
        sales: 0,
        salesGrowthRate: 0,
        profitRate: 0
    },
    {
        year: new Date().getFullYear() + 4,
        sales: 0,
        salesGrowthRate: 0,
        profitRate: 0
    },
]

interface MnaInfoProp {
    data: CustomerDetail
    brokerage: CustomerMnaBrokerageDto
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>
    setBrokerage: React.Dispatch<React.SetStateAction<CustomerMnaBrokerageDto | undefined>>
}

const MnAInfo = ({data, brokerage, setData, setBrokerage} : MnaInfoProp) => {
    const customerInputApi = useInjection(CustomerInputApi);
    const customerApi = useInjection(CustomerApi);
    const dialogManager = useInjection(DialogManager);

    let parentCode = "";
    let businessName = "";

    if (brokerage.bizAreaInfo && brokerage.bizAreaInfo !== null && brokerage.bizAreaInfo.length === 1 && brokerage.bizAreaInfo[0].parentCode === "해당영역 없음") {
        businessName = brokerage.bizAreaInfo[0].parentCode;
    } else {
        brokerage.bizAreaInfo?.map((info) => {
            if(parentCode === "") {
                parentCode = info.parentCode;
            } else {
                parentCode += "," + info.parentCode;
            }
    
            info.subCodes?.map((sub) => {
                if (businessName === "") {
                    businessName = sub;
                } else {
                    businessName += "," + sub;
                }
            });
        })
    }

    const saveExcel = async () => {
        const shareMemberNo = data.share && data.share.srcMember.memberNo;
        const loadResult = await customerInputApi.excelMnaBrokerage(data.customer!.id, shareMemberNo ?? "");

        if (loadResult.success) {
            window.location.href = customerApi.makeDownloadFile(loadResult.data!);
        } else {
            if (loadResult.message == "NoData") {
                dialogManager.alert("M&A 니즈 정보를 저장 후 다시 시도해주십시오.");
            } else {
                dialogManager.alert("상담시트 인쇄에 실패했습니다.");   
            }
        }
    }

    return(
        <Formik
            initialValues={{
                companyType: brokerage && brokerage.type && brokerage.type !== null ? brokerage.type : "NONE",
                mainProduct: brokerage && brokerage.mainProduct !== null ? brokerage.mainProduct : data.customer?.companyInfo.mainProduct ?? data.summary?.KED5002["PD_NM"],
                businessName: businessName,
                bizAreaInfo: brokerage && brokerage.bizAreaInfo,
                perform: brokerage && Object.keys(brokerage).length !== 0 && brokerage.earningsEstimates !== null && brokerage.earningsEstimates!.length > 0 ? brokerage.earningsEstimates : perform,
                bizCateDetail: brokerage && brokerage.bizCateDetail,
                mainSalesChannel: brokerage && brokerage.mainSalesChannel,
                estateAndProdLine: brokerage && brokerage.estateAndProdLine,
                uniqueSellingPoints: brokerage && brokerage.uniqueSellingPoints,
                synergisticPartners: brokerage && brokerage.synergisticPartners,
                salesReason: brokerage && brokerage.salesReason,
                salesMethod: brokerage && brokerage.salesMethod,
                planB: brokerage && brokerage.planB,
                experRecvAcqOffer: brokerage && brokerage.experRecvAcqOffer,
                keyExecutives: brokerage && brokerage.keyExecutives,
                salesAmount: brokerage && brokerage.salesAmount,

            }}
            onSubmit={async (values) => {
                const form: CustomerMnaBrokerageForm = {
                    shareMemberNo: data.share && data.share !== null ? data.share.srcMember.memberNo : "",
                    enpNm: data.customer?.enpNm ?? "",
                    ceoName: data.customer?.rep[0].name ?? "",
                    type: values.companyType ?? "NONE",
                    mainProduct: values.mainProduct ?? "",
                    bizAreaInfo: values.bizAreaInfo ?? [],
                    bizCateDetail: values.bizCateDetail ?? "",
                    earningsEstimates: values.perform ?? perform,
                    mainSalesChannel: values.mainSalesChannel ?? "",
                    estateAndProdLine: values.estateAndProdLine ?? "",
                    uniqueSellingPoints: values.uniqueSellingPoints ?? "",
                    synergisticPartners: values.synergisticPartners ?? "",
                    salesReason: values.salesReason ?? "",
                    salesMethod: values.salesMethod ?? "",
                    planB: values.planB ?? "",
                    experRecvAcqOffer: values.experRecvAcqOffer ?? "",
                    keyExecutives: values.keyExecutives ?? "",
                    salesAmount: values.salesAmount ?? ""
                };

                const result = await customerInputApi.updateMnaBrokerage(data.customer!.id, form);

                if (result.success) {
                    dialogManager.alert("저장되었습니다.");

                    const dto: CustomerMnaBrokerageDto = {
                        id: data.customer!.id,
                        type: form.type,
                        mainProduct: form.mainProduct,
                        bizAreaInfo: form.bizAreaInfo,
                        bizCateDetail: form.bizCateDetail,
                        earningsEstimates: form.earningsEstimates,
                        mainSalesChannel: form.mainSalesChannel,
                        estateAndProdLine: form.estateAndProdLine,
                        uniqueSellingPoints: form.uniqueSellingPoints,
                        synergisticPartners: form.synergisticPartners,
                        salesReason: form.salesReason,
                        salesMethod: form.salesMethod,
                        planB: form.planB,
                        experRecvAcqOffer: form.experRecvAcqOffer,
                        keyExecutives: form.keyExecutives,
                        salesAmount: form.salesAmount,
                        createAt: brokerage.createAt
                    }

                    data.customer!.companyInfo.mainProduct = form.mainProduct;

                    setBrokerage(dto);
                    setData(data => {
                        return {
                            ...data
                        }
                    })
                }

            }}>
            {({values}) =>

                <div className={cx('mna-info')}>
                    <Form>
                        <div className={cx('title-line')}>
                            <p className={cx('title')}>
                                M&A 니즈
                                <button className={cx('status-btn')} type='button' onClick={() => {dialogManager.open(MnaIntroductionFeePolicyPop, {})}}>M&A 소개수수료 정책<i></i></button>
                            </p>
                            <div className={cx('btn-area')}>
                                {(values.companyType === 'CHECK' || values.companyType === 'SELL') &&
                                    <button className={cx('print-btn')} type='button' onClick={() => {saveExcel()}}><i></i>상담시트 인쇄</button>
                                }
                                <button className={cx('save-btn')} type='submit' onClick={() => {}}><i></i>저장하기</button>
                            </div>
                        </div>
                        <div className={cx('content')}>
                            <div className={cx('company-type')} role='group'>
                                <p className={cx('type-title')}>니즈 유형</p>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='NONE'/>
                                    해당없음
                                </label>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='CHECK'/>
                                    검토가능
                                    <TooltipPop contents="매각 검토가능기업으로 등록만 해두면, 잠재 인수자가 해당 기업과 유사한 조건에 관심을 보일 경우 씨오파트너 회원의 소개 연결을 통해 고객사 미팅을 진행할 수 있습니다." eventStop={false} />
                                </label>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='SELL'/>
                                    매각희망
                                    <TooltipPop contents="매각희망기업으로 등록시 씨오파트너 M&A센터를 통해 고객사 미팅을 진행하고, M&A중개계약 체결 후 잠재 인수자를 찾아드립니다." eventStop={false} />
                                </label>
                                <label className={cx('radio-btn')}>
                                    <Field
                                        type='radio'
                                        name='companyType'
                                        value='ACQUIRE'/>
                                    인수희망
                                    {/* <TooltipPop contents="인수희망기업으로 등록시 씨오랩스 M&A 중개플랫폼을 통해 조건매칭 후보 매각기업을 추천받고, 인수중개를 진행할 수 있습니다. "/> */}
                                </label>
                            </div>
                            {
                                values.companyType === 'NONE' && <ConnectChannelTalk btnText='M&A 중개 문의'>
                                    <p className={cx('title')}><span>기업매각 니즈</span>를 가지는 고객사의 정보를 등록해 주세요.</p>
                                    <p className={cx('desc')}><span>검토가능기업</span> 또는 <span>매각희망기업</span>으로 등록시, 씨오파트너 M&A센터에서<br/>씨오파트너 회원에게 직접 연락드리고, M&A중개 절차를 안내해드립니다.</p>
                                </ConnectChannelTalk>
                            }
                            {
                                (values.companyType === 'CHECK' || values.companyType === 'SELL') && <CompanyTypeForm data={data} info={values.bizAreaInfo ?? []} perform={values.perform ?? perform} />
                            }
                            {
                                values.companyType === 'ACQUIRE' && <ConnectChannelTalk btnText='M&A 중개 문의'>
                                    <p className={cx('title')}><span>기업인수 니즈</span>를 가진 고객사가 있다면 씨오파트너 M&A센터로 문의주세요.</p>
                                    <p className={cx('desc')}>씨오파트너 M&A중개 플랫폼에 인수희망기업으로 등록 후<br/>조건매칭 후보 매각기업을 추천받고, 인수 중개를 진행할 수 있습니다.</p>
                                </ConnectChannelTalk>
                            }
                        </div>
                    </Form>
                </div>
            }
        </Formik>
    );
}

export default MnAInfo;