import React from "react";
import styles from '../mna-info.module.scss';
import classnames from 'classnames/bind';
import {Field, FormikValues, useFormikContext} from "formik";
import {InputBox, NumberInputBox} from "../edit-common";
import {useInjection} from "inversify-react";
import {DialogManager} from "../../../components/dialog-manager";
import BusinessFieldPop from "./business-field-pop";
import { CustomerDetail } from "../../../model/customer/dto/customer-detail";
import { CompanyPfKw, EarningsEstimate } from "../../../model/customer/dto/customer-mna-brokerage-dto";
import { PrFrStatCont } from "../../../model/customer/dto/financial-statement";

const cx = classnames.bind(styles);

const CompanyTypeForm = ({data, info, perform} : {data: CustomerDetail, info: CompanyPfKw[], perform: EarningsEstimate[]}) => {
    const dialogManager = useInjection(DialogManager);
    const {values, setFieldValue} = useFormikContext<FormikValues>();
    const today = new Date();
    
    let sales;
    let profit;
    let growth;

    const fss = data.fss && data.fss.length > 0 ? data.fss : undefined;
    const lastYear = today.getFullYear() - 1;

    if (fss) {
        for (let i = 0; i < fss[0].iss.length; i++) {
            const iss: PrFrStatCont = fss[0].iss[i];
            const stDate = Number(iss.stDate.substring(0, 4));

            if(lastYear === stDate) {
                sales = Number(iss.data["sales"] ?? 0);
                const opIncome = Number(iss.data["opIncome"] ?? 0);
                profit = sales === 0 ? 0 : (opIncome / sales) * 100;

                if (fss[0].iss.length > 1) {
                    if(i !== fss[0].iss.length - 1) {
                        const prevIss = fss[0].iss[i + 1];
                        const prevSales = Number(prevIss.data["sales"] ?? 0);
                        growth = prevSales === 0 ? 0 : (sales / prevSales) - 1;
                    }
                }

                break;
            }
        }
    }

    return (
        <div className={cx('company-type-form')}>
            <p className={cx('sub-title')}>1. 주요 제품</p>
            <InputBox name={'mainProduct'} inputWidth='100%' placeholder='입력'/>
            <p className={cx('sub-title')}>2. 사업영역</p>
            <InputBox name={'businessName'} inputWidth='100%' readOnly placeholder='입력' inputboxCn={cx('select-box')} onClick={() => {
                dialogManager.open(BusinessFieldPop, {info: info, setFieldValue: setFieldValue});
            }}/>
            <p className={cx('sub-title')}>3. 사업분야 상세 설명</p>
            <Field name={'bizCateDetail'} value={values.bizCateDetail || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('sub-title', 'gap2')}>4. {today.getFullYear()}년 매출액 및 영업이익 예상치, 향후 5년간 실적 전망</p>
            <div className={cx('flex-row-box')}>
                <div className={cx('flex-column-box')}>
                    <p className={cx('opacity-0')}>제목</p>
                    <p className={cx('no-input-box')}>매출액</p>
                    <p className={cx('no-input-box')}>매출액증가율</p>
                    <p className={cx('no-input-box')}>영업이익률</p>
                </div>
                <div className={cx('flex-column-box')}>
                    <p>{today.getFullYear() - 1}년</p>
                    {
                        fss ?
                        <>
                            <p className={cx('no-input-box')}>{sales ? (sales / 1e5).toFixed(2) + "억원" : "-"}</p>
                            <p className={cx('no-input-box')}>{growth ? (growth * 100).toFixed(1) + "%" : "-"}</p>
                            <p className={cx('no-input-box')}>{profit ? (profit).toFixed(1) + "%" : "-"}</p> 
                        </>
                        : 
                        <>
                            <p className={cx('no-input-box')}>-</p>
                            <p className={cx('no-input-box')}>-</p>
                            <p className={cx('no-input-box')}>-</p> 
                        </>
                    }
                    
                </div>
                {
                    perform.map((data, i) => {
                        return (
                            <div key={data.year} className={cx('flex-column-box')}>
                                <p>{data.year}년</p>
                                <NumberInputBox name={`perform.${i}.sales`} unit='억원' inputWidth='10.4rem'/>
                                <NumberInputBox name={`perform.${i}.salesGrowthRate`} unit='%' inputWidth='10.4rem'/>
                                <NumberInputBox name={`perform.${i}.profitRate`} unit='%' inputWidth='10.4rem'/>
                            </div>
                        )
                    })
                }
            </div>
            <p className={cx('sub-title')}>5. 해당 기업의 주요 매출처</p>
            <Field name={'mainSalesChannel'} value={values.mainSalesChannel || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('sub-title')}>6. 사업용 부동산 및 생산라인</p>
            <Field name={'estateAndProdLine'} value={values.estateAndProdLine || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('sub-title')}>7. 해당 사업 아이템의 경쟁우위 요소</p>
            <Field name={'uniqueSellingPoints'} value={values.uniqueSellingPoints || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('sub-title')}>8. 해당 기업을 인수했을 때 시너지를 낼 수 있는 기업(사업분야)</p>
            <Field name={'synergisticPartners'} value={values.synergisticPartners || ''} as='textarea' placeholder='입력' className={cx('text-area')}/>
            <p className={cx('divide-line-info')}>※ (9번 ~ 14번 ) M&A 어드바이저가 고객 상담 후 추가로 입력하는 항목입니다.</p>
            <p className={cx('sub-title')}>9. 기업매각사유</p>
            <Field name={'salesReason'} value={values.salesReason || ''} as='textarea' placeholder='입력' className={cx('text-area', 'ver2')}/>
            <p className={cx('sub-title')}>10. 희망하는 매각방식 <span>(*특정 조건 있을 경우 한에)</span></p>
            <Field name={'salesMethod'} value={values.salesMethod || ''} as='textarea' placeholder='입력' className={cx('text-area', 'ver2')}/>
            <p className={cx('sub-title')}>11. 매각이 어려울 경우 생각해 둔 B플랜 여부</p>
            <Field name={'planB'} value={values.planB || ''} as='textarea' placeholder='입력' className={cx('text-area', 'ver2')}/>
            <p className={cx('sub-title')}>12. 과거 기업인수 제안 받은 경험</p>
            <Field name={'experRecvAcqOffer'} value={values.experRecvAcqOffer || ''} as='textarea' placeholder='입력' className={cx('text-area', 'ver2')}/>
            <p className={cx('sub-title')}>13. 대표이사 이외 회사 경영 실무를 이끌어 갈 수 있는 핵심 임원 존재 여부</p>
            <Field name={'keyExecutives'} value={values.keyExecutives || ''} as='textarea' placeholder='입력' className={cx('text-area', 'ver2')}/>
            <p className={cx('sub-title')}>14. 매각희망금액 (산정 근거)</p>
            <Field name={'salesAmount'} value={values.salesAmount || ''} as='textarea' placeholder='입력' className={cx('text-area', 'ver2')}/>
        </div>
    );
}

export default CompanyTypeForm;